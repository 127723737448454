<template>
	<div :class="'item-container ' + brandName">
        <h1>{{ item.header }}</h1>
        <a class="btn" :href="item.url" target='_blank'>{{ item.btnText }}</a>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        item: Object
    },
    computed: {
        ...mapGetters(['brandName'])
    }
};
</script>

<style scoped>
.item-container {
    display: flex;
    max-width: 500px;
    justify-content: space-between;
    padding: 10px 0;
}
.item-container.PremiumClub {
    border-bottom: 1px dashed black;
}
.item-container.PremiumClub h1{
    color: black;
}
.item-container.apollogroup,
.item-container.pizzatime {
    border-bottom: 1px dashed white;
}
.item-container.apollogroup h1,
.item-container.pizzatime h1 {
    color: white;
}
h1 {
    font-size: 20px;
}
h1, .btn {
    color: white;
}
.btn {
    background-color: #f43259;
    width: 120px;
    text-decoration: none;
    height: 35px;
}
</style>
